.preloader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.preloader-container .preloader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  box-sizing: border-box;
}
.preloader-container .preloader div {
  box-sizing: border-box;
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.preloader-container .preloader div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.preloader-container .preloader div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.preloader-container .preloader div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.preloader-container .preloader div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq3p6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqw16aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Uw-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Shadows Into Light';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/shadowsintolight/v19/UqyNK9UOIntux_czAvDQx_ZcHqZXBNQDcg.ttf) format('truetype');
}
@font-face {
  font-family: Montserrat;
  src: url('../../../public/fonts/montserrat/Montserrat-VariableFont_wght.ttf') format('ttf'), url('../../../public/fonts/montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('ttf');
}
@media screen and (min-width: 1600px) {
  #slide-arrow {
    bottom: 70px;
  }
}
@media screen and (max-width: 1366px) {
  #nav-bar.fixed-width #nav-wrapper {
    width: 970px;
  }
}
@media screen and (max-width: 1200px) {
  #nav-bar.fixed-width #nav-wrapper {
    width: 100%;
  }
  .slide-title-inner-wrapper {
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .hide-on-mobile {
    display: none;
  }
  .hide-on-desktop {
    display: block;
  }
  #nav-bar-sticky-wrapper {
    height: 90px !important;
  }
  #nav-bar.fluid-width #nav-wrapper {
    padding: 0px 30px;
  }
  #nav-bar.fixed-width #nav-wrapper {
    padding: 0px 30px;
  }
  .logo-wrapper {
    margin-top: 15px !important;
  }
  #nav-menu {
    display: none;
    list-style-type: none;
    margin: 0;
    padding: 0 !important;
    position: absolute;
    left: 0;
    top: 100%;
    clear: both !important;
    text-align: left !important;
    width: 100% !important;
    background-color: #ffffff;
    height: 330px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    height: 230px !important;
  }
  #nav-menu ul {
    -webkit-overflow-scrolling: touch;
    left: 0;
    padding: 0 !important;
    display: block;
    width: 100%;
    position: relative;
    top: 0;
    text-align: left;
    border-top: none;
  }
  #nav-menu ul li:first-child a {
    border-radius: 0;
  }
  #nav-menu ul li:last-child a {
    border-radius: 0;
  }
  #nav-menu ul ul {
    display: block;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    border: none;
    padding: 0 !important;
  }
  #nav-menu ul ul li a:before {
    content: '- -';
    padding-right: 10px;
  }
  #nav-menu ul ul ul li a:before {
    content: '- - -';
    padding-right: 10px;
  }
  #nav-menu ul ul.on-left {
    left: 0;
  }
  #nav-menu li {
    position: relative;
  }
  #nav-menu li a {
    display: block;
    width: 100%;
    margin: 0;
    color: #666666;
    border: none !important;
    border-bottom: solid thin #dcdcdc !important;
    border-radius: 0 !important;
    padding: 18px 20px;
  }
  #nav-menu li a:hover {
    background-color: #f98d8a !important;
    color: #ffffff !important;
  }
  #nav-menu li a.active {
    color: #f98d8a;
    background-color: #ffffff;
  }
  #nav-menu li:before {
    content: '';
  }
  #nav-menu li > ul li a:before {
    content: '-';
    padding-right: 10px;
  }
  #nav-menu li > ul li a:after {
    content: '';
  }
  #nav-menu.sidebar {
    display: block;
  }
  #nav-menu.open {
    display: block;
  }
  .bottom-bar.transparent #nav-menu {
    list-style-type: none;
    margin: 0;
    padding: 0 !important;
    position: absolute;
    left: 0;
    top: 100%;
    clear: both !important;
    text-align: left !important;
    width: 100% !important;
    background-color: #ffffff;
    height: 330px;
    overflow: auto;
  }
  .transparent #nav-menu {
    width: 80% !important;
    position: absolute;
    margin: auto;
    top: 100%;
    left: 0;
    right: 0;
  }
  .transparent #nav-menu li a {
    color: #666666;
  }
  .transparent #nav-menu li a.active {
    color: #f98d8a;
    background-color: #ffffff;
  }
  .transparent #mobile-nav {
    color: #ffffff;
  }
  .transparent.stick-it #nav-menu {
    width: 100% !important;
  }
  .transparent.stick-it #mobile-nav {
    color: #666666;
  }
  #nav-bar.nav-center .logo-wrapper {
    position: relative;
    float: left;
  }
  #nav-bar.nav-left .logo-wrapper {
    position: relative;
    float: left;
  }
  #nav-bar.nav-right .logo-wrapper {
    position: relative;
    float: left;
  }
  #nav-bar.nav-center.hide-logo-on-desktop .logo-wrapper {
    display: block;
  }
  .nav-center .first-child-margin {
    margin: 0;
  }
  .nav-center .last-child-margin {
    margin: 0;
  }
  .nav-center .split-margin {
    margin: 0;
  }
  #mobile-nav {
    display: block;
    position: relative;
    float: right;
    font-size: 28px;
    margin-top: 30px;
    z-index: 1000;
  }
  #main-slider.flexslider:hover .flex-prev {
    opacity: 0.4;
    left: 20px;
  }
  #main-slider.flexslider:hover .flex-next {
    opacity: 0.4;
    right: 20px;
  }
  #main-slider.flexslider .flex-direction-nav .flex-prev {
    opacity: 0.4;
    left: 20px;
  }
  #main-slider.flexslider .flex-direction-nav .flex-next {
    opacity: 0.4;
    right: 20px;
  }
  #slide-couplename-wrapper {
    margin-top: 0;
  }
  .divider-wrapper {
    padding: 70px 0;
  }
  .image-divider {
    background-attachment: scroll;
  }
  #popup-template {
    width: 90%;
  }
  .popup-video {
    width: 80%;
  }
  .box-countdown .countdown-amount {
    font-size: 30px;
    width: 80%;
    padding-top: 38px;
  }
  .box-countdown .countdown-period {
    font-size: 16px;
    top: 75px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .content-block {
    padding: 60px 0;
  }
  .story-row.row {
    display: block;
    padding: 20px 0;
  }
  .story-row.row [class*='col-'] {
    display: block;
    text-align: center;
  }
  .story-date-wrapper {
    margin-bottom: 55px;
  }
  .story-date {
    float: none;
    position: absolute;
    top: -45px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .story-date .month-year {
    font-family: 'Oswald', sans-serif;
    font-size: 16px;
    color: #ffffff;
  }
  .arrow-right {
    display: none;
    float: none;
  }
  .arrow-left {
    display: none;
    float: none;
  }
  .vertical-line {
    display: none;
  }
  #last-row-date-wrapper {
    position: relative;
    padding: 0;
    margin-top: 30px;
  }
  #last-row-date-wrapper .story-date {
    position: relative;
    top: 0;
  }
  #end-of-story {
    margin-top: 20px;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 991px) and (orientation: landscape) {
  .scale75-onmobile {
    -ms-transform: scale(0.75, 0.75);
    -webkit-transform: scale(0.75, 0.75);
    transform: scale(0.75, 0.75);
  }
  #slide-other-text {
    font-size: 12px;
    letter-spacing: 4px;
    margin-top: 50px;
  }
}
@media screen and (max-width: 768px) {
  .socials {
    display: none !important;
  }
  .MuiPaper-root {
    top: 50%;
    transform: translate(0, -50%);
  }
  .box-countdown {
    height: 120px;
    margin-bottom: 20px;
  }
  .box-countdown .countdown-amount {
    font-size: 30px;
    width: 80%;
    padding-top: 34px;
  }
  .box-countdown .countdown-period {
    font-size: 16px;
    top: 64px;
    left: 0;
    right: 0;
    margin: auto;
  }
  form .btn {
    width: 100%;
    white-space: normal;
  }
}
@media screen and (max-width: 480px) {
  #hero .socials {
    display: none !important;
  }
  #hero .MuiPaper-root {
    top: 50%;
    transform: translate(0, -50%);
  }
  #hero .MuiTypography-h1 {
    font-size: 2rem !important;
  }
  #hero .MuiTypography-h3 {
    font-size: 2rem !important;
  }
  #hero .MuiTypography-h6 {
    font-size: 1rem !important;
  }
  .slogan {
    display: none !important;
  }
  h1 {
    font-size: 45px;
    letter-spacing: 3px;
  }
  .h1 {
    font-size: 45px;
    letter-spacing: 3px;
  }
  h1#accommodation-title {
    font-size: 36px;
    letter-spacing: 2px;
  }
  .scale75-onmobile {
    -ms-transform: scale(0.75, 0.75);
    -webkit-transform: scale(0.75, 0.75);
    transform: scale(0.75, 0.75);
  }
  .slide-title h1 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .slide-title .h1 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #slide-couplename-wrapper {
    margin-top: 0;
  }
  #slide-other-text {
    font-size: 12px;
    letter-spacing: 4px;
    margin-top: 50px;
  }
  .title-excerpt {
    padding: 20px;
  }
  #popup-template #the-content {
    padding-right: 30px;
    padding-left: 30px;
    text-align: center;
  }
  .box-countdown {
    height: 90px;
  }
  .box-countdown .countdown-amount {
    font-size: 26px;
    width: 80%;
    padding-top: 20px;
  }
  .box-countdown .countdown-period {
    font-size: 14px;
    top: 50px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .circle-countdown .countdown-amount {
    width: 45px;
    height: 45px;
    padding-top: 9px;
    font-size: 20px;
  }
  .circle-countdown .countdown-period {
    padding: 10px;
    display: block;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
  }
  .masonry-col {
    width: 100% !important;
    margin-bottom: 5% !important;
  }
  .grid-sizer {
    width: 100% !important;
    margin-bottom: 5% !important;
  }
  .profile-photo {
    width: 140px;
    height: 140px;
  }
}
@media screen and (max-width: 320px) {
  .couple-excerpt {
    margin-top: 20px;
  }
  footer .image-divider.fixed-height {
    height: 400px;
  }
}
#contact-us {
  text-align: center;
  margin-bottom: 0;
  min-width: 100vw;
}
#contact-us .checkcherry__widget__contact-form {
  max-width: 80%;
  margin: auto;
}
#contact-us .checkcherry__widget__contact-form .form-label {
  font-family: 'Montserrat', sans-serif;
}
#contact-us .checkcherry__widget__contact-form .form-control {
  margin-bottom: 1rem !important;
}
#contact-us .address {
  margin-top: 2em;
  margin-bottom: 2em;
}
#contact-us .address p {
  margin-bottom: 0;
}
#contact-us .margin-bottom {
  margin-bottom: 2em;
}
#contact-us .btn.btn-lg.btn-primary {
  margin: 3rem auto !important;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0;
  border: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 0.9375rem;
  text-transform: uppercase;
  min-width: 64px;
  padding: 0.5rem 1.375rem;
  border-radius: 4px;
  color: #f9f8f8;
  background-color: #e86c6c;
  text-align: center;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
#contact-us .btn.btn-lg.btn-primary:hover {
  text-decoration: none;
  background-color: #e86c6c !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
#hero button a {
  text-decoration: none;
  color: #f9f8f8;
}
#hero .logo {
  height: 150px;
  width: 150px;
}
.center-me {
  width: 40em;
  max-width: 80%;
  margin: 2em auto 3em;
}
.center-me p {
  text-align: center;
}
.bg-light-gray {
  background-image: url(../../../public/img/seigaiha.webp);
}
.no-top-margin {
  margin-top: 0 !important;
}
.top-padding {
  padding-top: 4rem;
}
